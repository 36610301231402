import { defineComponentClasses } from '@visiontree/vue-ui'

interface Structure {
  container: never
  input: never
  toggle: never
  content: never
  label: never
  help: never
}

type Options = Record<never, never>

export const getToggleClasses = defineComponentClasses<Options, Structure>({
  classes: {
    container: 'relative inline-flex cursor-pointer items-center gap-4',
    input: 'peer sr-only',
    toggle:
      "peer relative h-6 w-11 rounded-full bg-neutral-300 after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-neutral-200 after:bg-neutral-50 after:transition-all after:content-[''] peer-checked:bg-primary-300 peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:after:bg-primary-50 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-neutral-200 peer-focus:peer-checked:ring-primary-200 dark:border-neutral-600 dark:bg-neutral-700 dark:peer-checked:bg-neutral-600 dark:peer-focus:ring-neutral-500",
    content: 'flex flex-col',
    label: 'text-base font-medium text-neutral-900 dark:text-neutral-50',
    help: 'text-xs text-neutral-700 dark:text-neutral-300',
  },
})
