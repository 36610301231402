<template>
  <VtStack gap="xl" align-items="stretch">
    <VtStack gap="md" align-items="stretch">
      <VtHeading size="sm" :text="t('titles.themeSettings')" />
      <VtSelect
        v-model="currentColorMode"
        :label-text="t('controls.colorMode.labelText')"
        :options="themeModeOptions"
      />
    </VtStack>
    <VtStack gap="md" align-items="stretch">
      <VtHeading size="sm" :text="t('titles.layoutSettings')" />
      <VtCheckbox
        v-model="preferences.primarySidebar"
        :label-text="t('controls.primarySidebar.labelText')"
      />
      <VtCheckbox
        v-model="preferences.secondarySidebar"
        :label-text="t('controls.secondarySidebar.labelText')"
      />

      <VtToggle
        v-if="debugMode.enabled"
        :model-value="debugMode.enabled"
        :label-text="t('controls.debugMode.labelText')"
        @update:model-value="debugMode.disable"
      />
    </VtStack>
    <VtStack gap="md" align-items="stretch">
      <VtHeading size="sm" :text="t('titles.a11ySettings')" />
      <div>
        <VtRange
          v-model="preferences.fontSize"
          :min="0"
          :max="fontSizeOptions.length - 1"
          :step="1"
          label-text="Font size"
          list="settings-font-size-options"
        >
          <template #minLabel>
            <VtIcon name="text-size" size="sm" />
          </template>
          <template #maxLabel>
            <VtIcon name="text-size" size="lg" />
          </template>
        </VtRange>
        <datalist id="settings-font-size-options">
          <option
            v-for="(option, optionIndex) in fontSizeOptions"
            :key="option"
            :value="optionIndex"
          />
        </datalist>
      </div>
    </VtStack>
  </VtStack>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useI18n } from '#i18n'

import { VtRange, VtIcon } from '@visiontree/vue-ui'

import {
  VtStack,
  VtHeading,
  VtSelect,
  VtToggle,
  VtCheckbox,
} from '@/components/vue-ui'
import { useColorMode } from '@/composables/useColorMode'
import { useDebugMode } from '@/composables/useDebugMode'
import { useUserPreferences } from '@/composables/useUserPreferences'
import { fontSizeOptions } from '@/utils/userPreferences'

const { t } = useI18n()
const debugMode = useDebugMode()
const colorMode = useColorMode()
const preferences = useUserPreferences()

const currentColorMode = computed({
  get() {
    return colorMode.store.value
  },
  set(value) {
    colorMode.value = value
  },
})

const themeModeOptions = computed(() => {
  return [
    { text: t('controls.colorMode.options.light'), value: 'light' },
    { text: t('controls.colorMode.options.dark'), value: 'dark' },
    { text: t('controls.colorMode.options.system'), value: 'auto' },
  ]
})
</script>
