<template>
  <NuxtLink
    :to="{ name: 'applications' }"
    class="flex flex-col"
    :class="{ 'items-center gap-1': collapsed }"
  >
    <template v-if="collapsed">
      <img
        src="/brand-sm.svg"
        :alt="t('brand.name')"
        class="h-6 w-6 dark:hidden"
      />
      <img
        src="/brand-dark-sm.svg"
        :alt="t('brand.name')"
        class="hidden h-6 w-6 dark:block"
      />
    </template>
    <template v-else>
      <img
        src="/brand.svg"
        :alt="t('brand.name')"
        class="h-5 w-36 dark:hidden"
      />
      <img
        src="/brand-dark.svg"
        :alt="t('brand.name')"
        class="hidden h-5 w-36 dark:block"
      />
    </template>
    <span
      class="text-xs leading-none text-neutral-600 dark:text-neutral-400"
      translate="no"
    >
      {{ t('brand.appName') }}
    </span>
  </NuxtLink>
</template>

<script setup lang="ts">
import { NuxtLink } from '#components'
import { useI18n } from '#i18n'

const { t } = useI18n()

defineProps<{
  collapsed?: boolean
}>()
</script>
